import React from 'react'
import {
  ModalContainer,
  ModalHeader,
  ModalTitle,
  ModalCloseButton,
  ModalBody,
  Heading,
  Box,
  Grid,
  InjectedModalProps,
} from 'paybolt-uikit'
import useTheme from 'hooks/useTheme'
import styled from 'styled-components'
import { ChainId, NETWORK_NAMES } from 'config/constants/networks'
import { switchNetwork } from 'utils/web3Utils'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useStorage from 'hooks/useStorage'
import NetworkCard from './NetworkCard'

import EthereumIcon from '../Icons/EthereumIcon'
import PolygonIcon from '../Icons/PolygonIcon'
import BscIcon from '../Icons/BscIcon'

const WalletWrapper = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
`

interface NetworkSwitchModalProps extends InjectedModalProps {
  onNetworkSwitched: (chainId_: number) => void
  modalState?: true
}

export default function NetworkSwitchModal({
  onNetworkSwitched,
  onDismiss = () => null,
  modalState,
}: NetworkSwitchModalProps) {
  const theme = useTheme()
  const { chainId } = useActiveWeb3React()
  const { setItem } = useStorage()

  const networkMode = parseInt(process.env.NEXT_PUBLIC_REACT_APP_NETWORK_MODE)

  const toggleNetwork = (networkId: number) => {
    switchNetwork(networkId)
      .then((res) => {
        if (res) {
          onNetworkSwitched(networkId)
          onDismiss()
        }
      })
      .catch((err) => {
        onNetworkSwitched(networkId)
        onDismiss()
      })

    setItem('chain_id', networkId.toString())
  }

  return (
    <ModalContainer minWidth="320px">
      <ModalHeader background="linear-gradient(139.73deg,#3a56ed 0%,#F3EFFF 100%)">
        <ModalTitle>
          <Heading>Choose Network</Heading>
        </ModalTitle>
        <ModalCloseButton onDismiss={onDismiss} />
      </ModalHeader>
      <ModalBody width={['320px', null, '340px']}>
        <WalletWrapper py="24px" maxHeight="453px" overflowY="auto">
          <Grid gridTemplateColumns="1fr 1fr">
            <Box key={ChainId.MAINNET} m="0 10px">
              <NetworkCard
                title={NETWORK_NAMES[networkMode ? ChainId.MAINNET : ChainId.TESTNET]}
                icon={BscIcon}
                chainId={networkMode ? ChainId.MAINNET : ChainId.TESTNET}
                selected={networkMode ? chainId === ChainId.MAINNET : chainId === ChainId.TESTNET}
                onClick={toggleNetwork}
                onDismiss={onDismiss}
              />
            </Box>
            <Box key={ChainId.ETHEREUM} m="0 10px">
              <NetworkCard
                title={NETWORK_NAMES[networkMode ? ChainId.ETHEREUM : ChainId.RINKEBY]}
                icon={EthereumIcon}
                chainId={networkMode ? ChainId.ETHEREUM : ChainId.RINKEBY}
                selected={networkMode ? chainId === ChainId.ETHEREUM : chainId === ChainId.RINKEBY}
                onClick={toggleNetwork}
                onDismiss={onDismiss}
              />
            </Box>
            <Box key={ChainId.POLYGON} m="0 10px">
              <NetworkCard
                title={NETWORK_NAMES[networkMode ? ChainId.POLYGON : ChainId.MUMBAI]}
                icon={PolygonIcon}
                chainId={networkMode ? ChainId.POLYGON : ChainId.MUMBAI}
                selected={networkMode ? chainId === ChainId.POLYGON : chainId === ChainId.MUMBAI}
                onClick={toggleNetwork}
                onDismiss={onDismiss}
              />
            </Box>
          </Grid>
        </WalletWrapper>
      </ModalBody>
    </ModalContainer>
  )
}

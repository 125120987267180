export default {
  masterChef: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  },
  sousChef: {
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  lotteryV2: {
    97: '0x5790c3534F30437641541a0FA04C992799602998',
    56: '0x5aF6D33DE2ccEC94efb1bDF8f92Bd58085432d2c',
  },
  multiCall: {
    1: '0x605f4d2Ee9951180eC265d17781a51Fc46D84138',
    4: '0xe2d29c1D4e08b7F9D36113cFc13459771684A63a',
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
    137: '0x275617327c958bD06b5D6b871E7f491D76113dd8',
    80001: '0x1a8ce29e7DB63194095F40704eD470F0D13B2cca',
  },
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeRabbits: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetition: {
    56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
  },
  tradingCompetitionV2: {
    56: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    97: '',
  },
  easterNft: {
    56: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    97: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
  },
  cakeVault: {
    56: '0xa80240Eb5d7E05d3F250cF000eEc0891d00b51CC',
    97: '',
  },
  ifoPool: {
    56: '0x1B2A2f6ed4A1401E8C73B4c2B6172455ce2f78E8',
    97: '',
  },
  predictions: {
    56: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    97: '',
  },
  chainlinkOracle: {
    56: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    97: '',
  },
  bunnySpecialCakeVault: {
    56: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    97: '',
  },
  bunnySpecialPrediction: {
    56: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    97: '',
  },
  bunnySpecialLottery: {
    56: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    97: '0x382cB497110F398F0f152cae82821476AE51c9cF',
  },
  bunnySpecialXmas: {
    56: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    97: '',
  },
  farmAuction: {
    56: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    97: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
  AnniversaryAchievement: {
    56: '0x787980da5491118C3cB33B21aB50c8c379D2C552',
    97: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
  },
  nftMarket: {
    56: '0x17539cCa21C7933Df5c980172d22659B8C345C5A',
    97: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
  },
  nftSale: {
    56: '0x29fE7148636b7Ae0b1E53777b28dfbaA9327af8E',
    97: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
  },
  pancakeSquad: {
    56: '0x0a8901b0E25DEb55A87524f0cC164E9644020EBA',
    97: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
  },
  paybolt: {
    56: '0xe580074A10360404AF3ABfe2d524D5806D993ea3', // bsc
    97: '0x53f233c766281Ddaee1AA7d85cAF4846E1ff1491', // bsctestnet
    1: '0xe580074A10360404AF3ABfe2d524D5806D993ea3', // eth
    4: '0x9821178D7F173b9753c3D9079e32eFAaB459b776', // rinkedby
    137: '0xe580074A10360404AF3ABfe2d524D5806D993ea3', // polygon
    80001: '0xbEA5a04bAE3952a1a9C5782a30825e3E04e43339', // mumbai
  },
  payboltIdo: {
    56: '0x9F45951baBD39e1DB3796eEb6420540e8Dd073e1', // bsc
    97: '',
    1: '0x17EB063913c32234Ab4F72899144f05483A170a7', // eth
    4: '', // '0x187dCc47ef034b340B67A3617F6FDe1569a18Fb2', // rinkedby
    137: '0x9F45951baBD39e1DB3796eEb6420540e8Dd073e1', // polygon
    80001: '', // '0x3082252B5481A89df1cdf99577b85386AFF470D4', // mumbai
  },
  payboltIdoReflection: {
    56: '0x2A2c6460B669B73556E209540d1F34d0363D1912', // bsc
    97: '0x2A2c6460B669B73556E209540d1F34d0363D1912',
    1: '0xbE558510F72C2795803fcB54e8ed433fE4861510', // eth
    4: '', // '0xbE558510F72C2795803fcB54e8ed433fE4861510', // rinkedby
    137: '0xf2a61E093187D0E10269656c8D478D261315c4ff', // polygon
    80001: '', // '0xf2a61E093187D0E10269656c8D478D261315c4ff', // mumbai
  },
  bpayMigrator: {
    56: '0xc71D03668190Bcb9a68aAEBbb9c775d47b67d3cB',
    97: '0x599B1f6531D02dAd10D18F3c01a5D5720d2ff5A4',
  },
  bpayMigratorReflection: {
    56: '0xC2a2d95C14dDd964785F9c4A4844F1621DC8f475',
    97: '0xC2a2d95C14dDd964785F9c4A4844F1621DC8f475',
  },
  bpayBonus: {
    56: '0xa828F687Ffe0f53eB5be916d3c2Fa70Dc0767abC',
    97: '0xa828F687Ffe0f53eB5be916d3c2Fa70Dc0767abC',
  },
}

import React, { useMemo } from 'react'
import { ModalProvider, light as lightBase, dark as darkBase, PancakeTheme } from 'paybolt-uikit'
import { Web3ReactProvider } from '@web3-react/core'
import { Provider } from 'react-redux'
import { SWRConfig } from 'swr'
import { ThemeProvider } from 'styled-components'
import { useThemeManager } from 'state/user/hooks'
import { LanguageProvider } from 'contexts/Localization'
import { ToastsProvider } from 'contexts/ToastsContext'
import { fetchStatusMiddleware } from 'hooks/useSWRContract'
import { Store } from '@reduxjs/toolkit'

import { getTheme } from 'theme'

import Web3Provider from 'components/Web3Provider'

const themeObject = getTheme(true)

const light: PancakeTheme = {
  ...lightBase,
  ...themeObject,
};
light.colors.gradients.bubblegum = 'linear-gradient(139.73deg, #E5FDFF 0%, #F3EFFF 100%)'

// customize dark theme
const dark: PancakeTheme = {
  ...darkBase,
  ...themeObject,
};
dark.colors.gradients.bubblegum = 'linear-gradient(139.73deg, #E5FDFF 0%, #F3EFFF 100%)'
dark.modal.background = "#ffffff"

const ThemeProviderWrapper = (props) => {
  const [isDark] = useThemeManager()
  return <ThemeProvider theme={isDark ? light : light} {...props} />
}

const Providers: React.FC<{ store: Store }> = ({ children, store }) => {

  return (
    <Provider store={store}>
      <Web3Provider>
        <ToastsProvider>
          <ThemeProviderWrapper>
            <LanguageProvider>
              <SWRConfig
                value={{
                  use: [fetchStatusMiddleware],
                }}
              >
                <ModalProvider>{children}</ModalProvider>
              </SWRConfig>
            </LanguageProvider>
          </ThemeProviderWrapper>
        </ToastsProvider>
      </Web3Provider>
    </Provider>
  )
}

export default Providers

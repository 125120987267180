// import { ChainId } from '@pancakeswap/sdk'

export enum ChainId {
  MAINNET = 56,
  TESTNET = 97,
  ETHEREUM = 1,
  RINKEBY = 4,
  POLYGON = 137,
  MUMBAI = 80001,
}

export const NETWORK_NAMES: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: 'BNB Chain',
  [ChainId.TESTNET]: 'BSC Testnet',
  [ChainId.ETHEREUM]: 'Ethereum',
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.POLYGON]: 'Polygon',
  [ChainId.MUMBAI]: 'Mumbai',
}

const NETWORK_URLS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: 'https://bsc-dataseed1.defibit.io',
  [ChainId.TESTNET]: 'https://data-seed-prebsc-1-s1.binance.org:8545',
  [ChainId.ETHEREUM]: 'https://etherscan.io/',
  [ChainId.RINKEBY]: 'https://rinkeby.etherscan.io/',
  [ChainId.POLYGON]: 'https://polygonscan.com/',
  [ChainId.MUMBAI]: 'https://mumbai.polygonscan.com/',
}

export default NETWORK_URLS

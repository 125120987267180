import { Button, Flex, InjectedModalProps, LinkExternal, Skeleton, Text } from 'paybolt-uikit'
import { useWeb3React } from '@web3-react/core'
import tokens from 'config/constants/tokens'
import { FetchStatus } from 'config/constants/types'
import { ChainId } from 'config/constants/networks'
import { useTranslation } from 'contexts/Localization'
import useAuth from 'hooks/useAuth'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useTokenBalance, { useTokenDecimals } from 'hooks/useTokenBalance'
import React from 'react'
import { getScanLink } from 'utils'
import { getFullDisplayBalance } from 'utils/formatBalance'
import CopyAddress from './CopyAddress'

interface WalletInfoProps {
  hasLowBnbBalance: boolean
  onDismiss: InjectedModalProps['onDismiss']
}

const WalletInfo: React.FC<WalletInfoProps> = ({ hasLowBnbBalance, onDismiss }) => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { chainId } = useActiveWeb3React()
  const { balance: payBalance, fetchStatus: cakeFetchStatus } = useTokenBalance(tokens.pay.address[chainId])
  const { decimals, fetchStatus } = useTokenDecimals(tokens.pay.address[chainId])
  const { logout } = useAuth()

  const handleLogout = () => {
    onDismiss()
    logout()
  }

  return (
    <>
      <Text color="secondary" fontSize="12px" textTransform="uppercase" fontWeight="bold" mb="8px">
        {t('Your Address')}
      </Text>
      <CopyAddress account={account} mb="24px" />
      <Flex alignItems="center" justifyContent="space-between" mb="24px">
        <Text color="textSubtle">{t('PAY Balance')}</Text>
        {cakeFetchStatus !== FetchStatus.Fetched ? (
          <Skeleton height="22px" width="60px" />
        ) : (
          <Text>{getFullDisplayBalance(payBalance, decimals.toNumber(), 3)}</Text>
        )}
      </Flex>
      <Flex alignItems="center" justifyContent="end" mb="24px">
        <LinkExternal href={getScanLink(account, 'address', chainId)}>
          View on{' '}
          {chainId === ChainId.ETHEREUM
            ? 'Etherscan'
            : chainId === ChainId.MAINNET
            ? 'BscScan'
            : chainId === ChainId.POLYGON
            ? 'PolygonScan'
            : ''}
        </LinkExternal>
      </Flex>
      <Button variant="secondary" width="100%" onClick={handleLogout}>
        {t('Disconnect Wallet')}
      </Button>
    </>
  )
}

export default WalletInfo

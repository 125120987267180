import React from 'react'

export default function TelegramIcon({ ...props }) {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28ZM14.6054 10.5065C13.3515 11.028 10.8455 12.1074 7.08743 13.7448C6.47717 13.9874 6.15749 14.2248 6.12839 14.457C6.07921 14.8493 6.5705 15.0038 7.23952 15.2142C7.33052 15.2428 7.42482 15.2724 7.52148 15.3038C8.17969 15.5178 9.0651 15.7681 9.52539 15.7781C9.94291 15.7871 10.4089 15.6149 10.9234 15.2617C14.4347 12.8914 16.2472 11.6934 16.361 11.6676C16.4413 11.6494 16.5526 11.6264 16.628 11.6934C16.7033 11.7604 16.6959 11.8873 16.6879 11.9214C16.6393 12.1288 14.7108 13.9218 13.7128 14.8496C13.4016 15.1389 13.1809 15.344 13.1358 15.3909C13.0348 15.4959 12.9318 15.5952 12.8328 15.6906C12.2213 16.2801 11.7627 16.7221 12.8581 17.444C13.3846 17.7909 13.8058 18.0778 14.2261 18.364C14.685 18.6765 15.1428 18.9883 15.7351 19.3765C15.886 19.4754 16.0301 19.5782 16.1704 19.6782C16.7046 20.059 17.1844 20.4011 17.7772 20.3465C18.1217 20.3148 18.4775 19.9909 18.6582 19.0249C19.0853 16.7418 19.9248 11.7951 20.1188 9.75666C20.1358 9.57806 20.1144 9.3495 20.0972 9.24917C20.08 9.14883 20.0442 9.00588 19.9137 8.90005C19.7593 8.77472 19.5208 8.74829 19.4142 8.75008C18.9293 8.75871 18.1854 9.01738 14.6054 10.5065Z"
        fill="currentColor"
      />
    </svg>
  )
}

import { Web3ReactHooks } from '@web3-react/core'
import { Connector } from '@web3-react/types'
import { SupportedChainId } from 'constants/chains'
import { StaticImageData } from 'next/image';

export enum ConnectionType {
  /** @deprecated - Use {@link UNISWAP_WALLET} instead. */
  INJECTED = 'INJECTED',
  COINBASE_WALLET = 'COINBASE_WALLET',
  WALLET_CONNECT_V2 = 'WALLET_CONNECT_V2',
  NETWORK = 'NETWORK',
  GNOSIS_SAFE = 'GNOSIS_SAFE',
}

export interface Connection {
  getName(): string
  connector: Connector
  hooks: Web3ReactHooks
  type: ConnectionType
  getIcon?(): string | StaticImageData
  shouldDisplay(): boolean
  overrideActivate?: (chainId?: SupportedChainId) => boolean
  isNew?: boolean
}

import React from 'react'

export default function EthereumIcon({ ...props }) {
  return (
    <svg width="30" height="40" viewBox="0 0 157.59 256.65" fill="none" {...props}>
      <polygon points="78.76 0 0 130.7 78.76 94.91 78.76 0" fill="#8a92b2" />
      <polygon points="78.76 94.91 0 130.7 78.76 177.27 78.76 94.91" fill="#62688f" />
      <polygon points="157.54 130.7 78.76 0 78.76 94.91 157.54 130.7" fill="#62688f" />
      <polygon points="78.76 177.27 157.54 130.7 78.76 94.91 78.76 177.27" fill="#454a75" />
      <polygon points="0 145.65 78.76 256.65 78.76 192.19 0 145.65" fill="#8a92b2" />
      <polygon points="78.76 192.19 78.76 256.65 157.59 145.65 78.76 192.19" fill="#62688f" />
    </svg>
  )
}

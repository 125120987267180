import React from 'react'

export default function UsersIcon({ ...props }) {
  return (
    <svg width="38" height="29" viewBox="0 0 38 29" fill="none" {...props}>
      <path
        d="M11.2701 0C7.53117 0 4.48935 3.22517 4.48935 7.18941C4.48935 11.1537 7.53117 14.3788 11.2701 14.3788C15.0091 14.3788 18.051 11.1537 18.051 7.18941C18.051 3.22517 15.0091 0 11.2701 0Z"
        fill="#3D4459"
      />
      <path
        d="M26.6864 1.02887C26.6711 1.02887 26.6556 1.02894 26.6403 1.02901C25.0712 1.042 23.6055 1.70452 22.5134 2.89468C21.4404 4.0638 20.857 5.60615 20.8704 7.23753C20.8839 8.86891 21.4927 10.4014 22.5848 11.5527C23.6855 12.713 25.1435 13.3502 26.6952 13.3502C26.7108 13.3502 26.7264 13.3501 26.7421 13.35C28.3112 13.3371 29.7769 12.6745 30.869 11.4844C31.942 10.3153 32.5254 8.77291 32.512 7.14146C32.484 3.76072 29.8752 1.02887 26.6864 1.02887Z"
        fill="#3D4459"
      />
      <path
        d="M26.9725 14.5272H26.4094C23.7872 14.5272 21.3943 15.5242 19.5876 17.1582C20.0396 17.505 20.4699 17.883 20.8777 18.2908C22.0807 19.4938 23.0254 20.8956 23.6856 22.457C24.2806 23.8632 24.6202 25.3448 24.6992 26.8707H37.1494V24.7034C37.1494 19.0925 32.5841 14.5272 26.9725 14.5272Z"
        fill="#3D4459"
      />
      <path
        d="M22.5182 26.8705C22.3128 23.5626 20.636 20.6531 18.1335 18.7883C16.3131 17.4315 14.0551 16.6283 11.6092 16.6283H10.9315C4.89399 16.6284 0 21.5223 0 27.5598V28.8571H22.5399V27.5598C22.5399 27.3283 22.5327 27.0983 22.5182 26.8705Z"
        fill="#3D4459"
      />
    </svg>
  )
}

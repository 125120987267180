import React from 'react'
import { NextLinkFromReactRouter } from 'components/NextLink'
import styled from 'styled-components'
import { useRouter } from 'next/router'

const Wrapper = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: ${({ active }) => (active ? 600 : 500)};
  color: ${({ active }) => (active ? '#FFFFFF' : '#36384E')};
  font-size: 17px;
  line-height: 17px;
  margin-bottom: 24px;
`

const Icon = styled.div<{ active: boolean }>`
  background-color: ${({ active }) => (active ? 'white' : 'transparent')};
  border: 1px solid ${({ active }) => (active ? '#FFFFFF' : '#36384E')};
  margin-right: 18px;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Title = styled.div``

export default function MenuItem({ icon, title, to }: { icon: any; title: string; to: string; active: boolean }) {
  const router = useRouter()

  const isActive = () => {
    return router.pathname === to
  }

  return (
    <NextLinkFromReactRouter to={to} prefetch={false}>
      <Wrapper active={isActive()}>
        <Icon active={isActive()}>{icon}</Icon>
        <Title>{title}</Title>
      </Wrapper>
    </NextLinkFromReactRouter>
  )
}

import { useEffect, useState, useRef } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { simpleRpcProvider } from 'utils/providers'
import { ChainId } from 'config/constants/networks'
// eslint-disable-next-line import/no-unresolved
import useStorage from 'hooks/useStorage'
import usePreviousValue from 'hooks/usePreviousValue'

const useActiveWeb3React = () => {
  const { account, chainId, connector, provider, ...rest } = useWeb3React();

  const customOverride = {
    ...rest,
    chainId,
    connector,
    account,
    provider,
    library: provider,
  }
  return customOverride;
};


/**
 * Provides a web3 provider with or without user's signer
 * Recreate web3 instance only if the provider change
 */
/*
const useActiveWeb3React = (): Web3ReactContextInterface<Web3Provider> => {
  const networkMode = parseInt(process.env.NEXT_PUBLIC_REACT_APP_NETWORK_MODE)
  const { getItem } = useStorage()
  const { library, chainId, ...web3React } = useWeb3React()
  const refEth = useRef(library)
  const [provider, setProvider] = useState(library || simpleRpcProvider(chainId))

  let _chainId
  if (chainId === undefined) {
    _chainId = parseInt(getItem('chain_id'))
    if (Number.isNaN(_chainId)) {
      _chainId = networkMode ? ChainId.MAINNET : ChainId.TESTNET
    }
  } else {
    _chainId = chainId
  }

  useEffect(() => {
    if (library !== refEth.current) {
      setProvider(library || simpleRpcProvider(_chainId))
      refEth.current = library
    }
  }, [library, _chainId])

  return { library: provider, chainId: _chainId, ...web3React }
}
*/

export default useActiveWeb3React

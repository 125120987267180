import Web3 from 'web3'
import { ChainId, NETWORK_NAMES } from 'config/constants/networks'

declare const window: any

const chainInfoMap: any = {
  137: {
    chainName: NETWORK_NAMES[ChainId.POLYGON],
    rpcUrls: ['https://polygon-rpc.com'],
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    blockExplorerUrls: ['https://polygonscan.com/'],
  },
  1: {
    chainName: NETWORK_NAMES[ChainId.ETHEREUM],
    rpcUrls: ['https://mainnet.infura.io/v3/679be12b168f444585254ff4874aaa9a'],
    nativeCurrency: {
      name: 'ETHEREUM',
      symbol: 'ETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://etherscan.io/'],
  },
  56: {
    chainName: NETWORK_NAMES[ChainId.MAINNET],
    rpcUrls: [
      'https://bsc-dataseed.binance.org',
      'https://bsc-dataseed1.ninicoin.io',
      'https://bsc-dataseed1.defibit.io',
    ],
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    blockExplorerUrls: ['https://bscscan.com/'],
  },
}

export const switchNetwork = (chainId: number): Promise<any> => {
  return new Promise((resolve) => {
    ;(window as any).ethereum
      .request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      })
      .then(() => {
        resolve(true)
      })
      .catch((error: any) => {
        //console.log(error.code)
        if (error.code === 4902) {
          ;(window as any).ethereum
            .request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: `0x${chainId.toString(16)}`,
                  ...chainInfoMap[chainId],
                },
              ],
            })
            .then((result) => {
              //console.log(result)
              resolve(true)
            })
            .catch((_error) => {
              //console.log(_error)
            })
        } else {
          resolve(false)
        }
      })
  })
}

export const ContractInstance = (web3: Web3, abi: any, address: string): any => {
  const contract = new web3.eth.Contract(abi, address)
  return contract
}

export const getCorrectDecValue = (value: number, decimal: number) => {
  return value / 10 ** decimal
}

import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'

import { ChainId, NETWORK_NAMES } from 'config/constants/networks'
import { useModal } from 'paybolt-uikit'
import { useDetectClickOutside } from 'hooks/useDetectClickOutside'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useToast from 'hooks/useToast'

import NetworkSwitchModal from 'components/NetworkSwitchModal'

import { FacebookIcon, TelegramIcon, TwitterIcon, YoutubeIcon, InstagramIcon } from '../Icons'

import SideMenu from './SideMenu'
import UserMenu from './UserMenu'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  color: #fff;
`

const HeaderTop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #1f2536;
  background-color: #121319;
  z-index: 911;

  @media (max-width: 967px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`

const Logo = styled.div`
  @media (max-width: 967px) {
    margin-right: auto;
  }

  img {
    display: block;
  }
`

const Main = styled.div`
  display: flex;
  padding: 90px 0 0 265px;
  height: 100%;

  @media (max-width: 967px) {
    padding-left: 0;
    padding-right: 0;
  }
`

const Wrapper = styled.div`
  padding: 40px;
  width: 100%;

  @media (max-width: 967px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`

const BurgerMenu = styled.div`
  display: none;
  margin-right: 16px;
  cursor: pointer;
  transition: opacity 0.2s ease;

  @media (max-width: 967px) {
    display: flex;
  }

  @media (max-width: 575px) {
    margin-right: 10px;
  }

  &:hover {
    opacity: 0.5;
  }

  svg {
    path {
      transition: all 0.3s ease-in-out;
    }
  }

  &.is-active {
    svg {
      path {
        &:nth-child(1) {
          opacity: 0;
        }

        &:nth-child(2) {
          transform: translateX(8px) rotate(45deg);
        }

        &:nth-child(3) {
          transform: translateX(-9px) translateY(9px) rotate(-45deg);
        }
      }
    }
  }
`

const SideMenuWrapper = styled.div`
  position: fixed;
  top: 90px;
  left: 0;
  bottom: 0;
  padding: 40px 40px;
  display: flex;
  max-width: 255px;
  width: 100%;
  flex: 1 1 255px;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 967px) {
    position: fixed;
    top: 74px;
    left: 0;
    bottom: 0;
    padding: 24px;
    display: none;
    z-index: 911;
    background-color: #121319;

    &.is-active {
      display: flex;
    }
  }
`

const SocialNav = styled.div`
  display: flex;
  align-items: center;
`

const SocialNavItem = styled.div`
  margin-right: 16px;

  a {
    display: flex;
    align-items: center;
    width: 28px;
    height: 28px;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    border-radius: 50%;
    color: #cecfde;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: white;
    }
  }
`

const NetworkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
`

const NetworkButton = styled.button`
  color: #ffffff;
  background: linear-gradient(90deg, #6a11cb 0%, #2575fc 100%);

  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  padding: 0 10px;
  font-size: 12px;
  line-height: 1;
  font-weight: bold;
  text-align: center;
  height: 52px;
  letter-spacing: 0.04em;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.3 ease;
  margin-right: 10px;
  margin-left: 10px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0 15px;
    min-width: 150px;
    font-size: 15px;
    line-height: 1;
    margin-right: 20px !important;

    svg {
      display: block !important;
    }
  }

  &:hover {
    opacity: 0.8;
  }

  &.mainnet {
    color: #ffffff;
    background: linear-gradient(90deg, #6a11cb 0%, #2575fc 100%);
  }

  &.connect {
    margin-left: 15px;
    color: #454545;
    background-color: #ffffff;
  }

  svg {
    margin-right: 10px;
    display: none;
  }
`

const Menu = (props) => {
  const { children } = props
  const router = useRouter()
  const { toastSuccess, toastError } = useToast()
  const [isActive, setIsActive] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const { chainId } = useActiveWeb3React()
  const networkMode = parseInt(process.env.NEXT_PUBLIC_REACT_APP_NETWORK_MODE)

  const [networkName, setNetworkName] = useState('')

  const handleNetworkSwitched = (chainId_: any) => {
    if (
      !(
        chainId === ChainId.ETHEREUM ||
        chainId === ChainId.RINKEBY ||
        chainId === ChainId.MAINNET ||
        chainId === ChainId.TESTNET ||
        chainId === ChainId.POLYGON ||
        chainId === ChainId.MUMBAI
      )
    ) {
      toastError('Invalid Chain', 'Presale is only available in BSC, Ethereum and Polygon')
    }
  }
  const [onNetworkSwitchModal] = useModal(<NetworkSwitchModal onNetworkSwitched={handleNetworkSwitched} />)

  const toggleMenu = () => {
    document.body.style.overflow = document.body.style.overflow === 'hidden' ? '' : 'hidden'
    setIsActive(!isActive)
  }

  const closeMenu = () => {
    document.body.style.overflow = ''
    setIsActive(false)
  }

  useEffect(() => {
    let _networkName
    switch (chainId) {
      case ChainId.ETHEREUM:
      case ChainId.POLYGON:
      case ChainId.MAINNET:
        _networkName = NETWORK_NAMES[chainId]
        break
      case ChainId.RINKEBY:
      case ChainId.MUMBAI:
      case ChainId.TESTNET:
        _networkName = networkMode ? 'Unsupported Network' : NETWORK_NAMES[chainId]
        break
      default:
        _networkName = 'Unsupported Network'
    }
    setNetworkName(_networkName)
  }, [chainId])

  useEffect(() => {
    if (router.pathname) {
      closeMenu()
    }
  }, [router.pathname])

  const onSwitchNetwork = () => {
    onNetworkSwitchModal()
  }

  const ref = useDetectClickOutside({ onTriggered: () => (isActive ? closeMenu() : false) })

  return (
    <Container>
      <HeaderTop>
        <BurgerMenu onClick={toggleMenu} className={`${isActive ? 'is-active' : ''}`}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 12H21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3 6H21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3 18H21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </BurgerMenu>
        <Logo>
          <img src="/images/logo-icon.svg" alt="Logo" />
        </Logo>

        <NetworkContainer>
          {!isMobile && (
            <NetworkButton type="button" onClick={onSwitchNetwork}>
              {chainId === ChainId.ETHEREUM || chainId === ChainId.RINKEBY ? (
                <svg
                  width="16"
                  height="20"
                  viewBox="-38.39985 -104.22675 332.7987 625.3605"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="currentColor" d="M125.166 285.168l2.795 2.79 127.962-75.638L127.961 0l-2.795 9.5z" />
                  <path fill="currentColor" d="M127.962 287.959V0L0 212.32z" />
                  <path fill="currentColor" d="M126.386 412.306l1.575 4.6L256 236.587l-128.038 75.6-1.575 1.92z" />
                  <path fill="currentColor" d="M0 236.585l127.962 180.32v-104.72z" />
                  <path fill="currentColor" d="M127.961 154.159v133.799l127.96-75.637z" />
                  <path fill="currentColor" d="M127.96 154.159L0 212.32l127.96 75.637z" />
                </svg>
              ) : chainId === ChainId.MAINNET || chainId === ChainId.TESTNET ? (
                <svg width="16" height="20" viewBox="0 0 2500.01 2500" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill="currentColor"
                    d="M764.48,1050.52,1250,565l485.75,485.73,282.5-282.5L1250,0,482,768l282.49,282.5M0,1250,282.51,967.45,565,1249.94,282.49,1532.45Zm764.48,199.51L1250,1935l485.74-485.72,282.65,282.35-.14.15L1250,2500,482,1732l-.4-.4,282.91-282.12M1935,1250.12l282.51-282.51L2500,1250.1,2217.5,1532.61Z"
                  />
                  <path
                    fill="currentColor"
                    d="M1536.52,1249.85h.12L1250,963.19,1038.13,1175h0l-24.34,24.35-50.2,50.21-.4.39.4.41L1250,1536.81l286.66-286.66.14-.16-.26-.14"
                  />
                </svg>
              ) : chainId === ChainId.POLYGON || chainId === ChainId.MUMBAI ? (
                <svg width="16" height="20" viewBox="0 0 38.4 33.5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill="currentColor"
                    d="M29,10.2c-0.7-0.4-1.6-0.4-2.4,0L21,13.5l-3.8,2.1l-5.5,3.3c-0.7,0.4-1.6,0.4-2.4,0L5,16.3
                      c-0.7-0.4-1.2-1.2-1.2-2.1v-5c0-0.8,0.4-1.6,1.2-2.1l4.3-2.5c0.7-0.4,1.6-0.4,2.4,0L16,7.2c0.7,0.4,1.2,1.2,1.2,2.1v3.3l3.8-2.2V7
                      c0-0.8-0.4-1.6-1.2-2.1l-8-4.7c-0.7-0.4-1.6-0.4-2.4,0L1.2,5C0.4,5.4,0,6.2,0,7v9.4c0,0.8,0.4,1.6,1.2,2.1l8.1,4.7
                      c0.7,0.4,1.6,0.4,2.4,0l5.5-3.2l3.8-2.2l5.5-3.2c0.7-0.4,1.6-0.4,2.4,0l4.3,2.5c0.7,0.4,1.2,1.2,1.2,2.1v5c0,0.8-0.4,1.6-1.2,2.1
                      L29,28.8c-0.7,0.4-1.6,0.4-2.4,0l-4.3-2.5c-0.7-0.4-1.2-1.2-1.2-2.1V21l-3.8,2.2v3.3c0,0.8,0.4,1.6,1.2,2.1l8.1,4.7
                      c0.7,0.4,1.6,0.4,2.4,0l8.1-4.7c0.7-0.4,1.2-1.2,1.2-2.1V17c0-0.8-0.4-1.6-1.2-2.1L29,10.2z"
                  />
                </svg>
              ) : (
                <></>
              )}

              <span>{networkName}</span>
            </NetworkButton>
          )}
          <>
            <UserMenu />
          </>
        </NetworkContainer>
      </HeaderTop>
      <Main>
        <SideMenuWrapper ref={ref} className={`${isActive ? 'is-active' : ''}`}>
          <SideMenu />
          <SocialNav>
            <SocialNavItem>
              <a href="https://www.facebook.com/PayBolt" target="_blank" rel="noreferrer" title="Facebook">
                <FacebookIcon />
              </a>
            </SocialNavItem>
            <SocialNavItem>
              <a href="https://t.me/PayBolt" target="_blank" rel="noreferrer" title="Telegram">
                <TelegramIcon />
              </a>
            </SocialNavItem>
            <SocialNavItem>
              <a href="https://twitter.com/PayBoltofficial" target="_blank" rel="noreferrer" title="Twitter">
                <TwitterIcon />
              </a>
            </SocialNavItem>
            <SocialNavItem>
              <a href="https://www.youtube.com/c/PayBolt" target="_blank" rel="noreferrer" title="Youtube">
                <YoutubeIcon />
              </a>
            </SocialNavItem>
            <SocialNavItem>
              <a href="https://www.instagram.com/PayBolt/" target="_blank" rel="noreferrer" title="Instagram">
                <InstagramIcon />
              </a>
            </SocialNavItem>
          </SocialNav>
        </SideMenuWrapper>
        <Wrapper>{children}</Wrapper>
      </Main>
    </Container>
  )
}

export default Menu

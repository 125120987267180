import React from 'react'

export default function FacebookIcon({ ...props }) {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14ZM14 7C17.85 7 21 10.15 21 14C21 17.5 18.4625 20.475 14.9625 21V16.0125H16.625L16.975 14H15.05V12.6875C15.05 12.1625 15.3125 11.6375 16.1875 11.6375H17.0625V9.8875C17.0625 9.8875 16.275 9.7125 15.4875 9.7125C13.9125 9.7125 12.8625 10.675 12.8625 12.425V14H11.1125V16.0125H12.8625V20.9125C9.5375 20.3875 7 17.5 7 14C7 10.15 10.15 7 14 7Z"
        fill="currentColor"
      />
    </svg>
  )
}

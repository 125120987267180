import BigNumber from 'bignumber.js'
import poolsConfig from 'config/constants/pools'
import tierMasterchefABI from 'config/abi/tierMasterchef.json'
import wbnbABI from 'config/abi/weth.json'
import multicall from 'utils/multicall'
import { getAddress } from 'utils/addressHelpers'
import { BIG_ZERO } from 'utils/bigNumber'
import tokens from 'config/constants/tokens'

export const fetchPoolsTotalStaking = async (chainId: number, account: string) => {
  const nonBnbPools = poolsConfig.filter((p) => p.stakingToken.symbol !== 'BNB' && p.contractAddress[chainId] !== '')
  const bnbPool = poolsConfig.filter((p) => p.stakingToken.symbol === 'BNB' && p.contractAddress[chainId] !== '')

  const callsNonBnbPools = nonBnbPools.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress, chainId),
      name: 'poolInfo',
      params: [poolConfig.tierIndex],
    }
  })

  const callsBnbPools = bnbPool.map((poolConfig) => {
    return {
      address: tokens.wbnb.address[chainId],
      name: 'balanceOf',
      params: [getAddress(poolConfig.contractAddress, chainId)],
    }
  })

  const nonBnbPoolsInfo = await multicall(tierMasterchefABI, callsNonBnbPools, chainId)
  const bnbPoolsTotalStaked = await multicall(wbnbABI, callsBnbPools, chainId)

  return [
    ...nonBnbPools.map((p) => {
      const index = nonBnbPools.findIndex((cell) => cell.sousId === p.sousId)
      const totalSupply = nonBnbPoolsInfo[index] ? nonBnbPoolsInfo[index].totalSupply?._hex : BIG_ZERO
      const totalStaked = new BigNumber(totalSupply).toJSON()

      const apr = nonBnbPoolsInfo[index] ? nonBnbPoolsInfo[index].apr?._hex : BIG_ZERO
      const minStakeAmount = nonBnbPoolsInfo[index] ? nonBnbPoolsInfo[index].minStakeAmount?._hex : BIG_ZERO
      const timeLocked = nonBnbPoolsInfo[index] ? nonBnbPoolsInfo[index].timeLocked?._hex : BIG_ZERO

      return {
        sousId: p.sousId,
        totalStaked,
        apr: new BigNumber(apr).toJSON(),
        minStakeAmount: new BigNumber(minStakeAmount).toJSON(),
        timeLocked: new BigNumber(timeLocked).toJSON(),
      }
    }),
    ...bnbPool.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber(bnbPoolsTotalStaked[index]).toJSON(),
    })),
  ]
}

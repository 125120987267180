import React from 'react'

export default function BridgeIcon({ ...props }) {
  return (
    <svg width="32" height="32" viewBox="-80 -80 610 610" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="-80" y="-80" width="610" height="610" rx="8" fill="currentColor" />
      <path
        d="M455,114.25v-30H0v30h30v40H0v216.5h65.993c0,0,0-59.767,0-59.767c0-35.435,28.768-64.161,64.256-64.161
  c35.488,0,64.256,28.726,64.256,64.161L194.5,370.75h65.996v-59.767c0-35.435,28.768-64.161,64.256-64.161
  s64.256,28.726,64.256,64.161c0,0,0,59.767,0,59.767H455v-216.5h-30v-40H455z M212.5,154.25H60v-40h152.5V154.25z M395,154.25H242.5
  v-40H395V154.25z"
        fill="black"
      />
    </svg>
  )
}

import { useWeb3React } from '@web3-react/core'
import IconButton from 'components/Button/IconButton'
import { AutoColumn } from 'components/Column'
import { AutoRow } from 'components/Row'
import { getConnections, networkConnection } from 'connection'
import { ActivationStatus, useActivationState } from 'connection/activate'
import { isSupportedChain } from 'constants/chains'
import { useEffect } from 'react'
import { Settings } from 'react-feather'
import styled from 'styled-components'
import { flexColumnNoWrap } from 'theme/styles'

import ConnectionErrorView from './ConnectionErrorView'
import Option from './Option'

import {
  ModalContainer,
  ModalHeader,
  ModalTitle,
  ModalCloseButton,
  ModalBody,
  Heading,
  Box,
  Grid,
  InjectedModalProps,
} from 'paybolt-uikit'

const Wrapper = styled.div`
  ${flexColumnNoWrap};
  background-color: ${({ theme }) => theme.backgroundSurface};
  width: 100%;
  padding: 14px 16px 16px;
  flex: 1;
`

const OptionGrid = styled.div`
  display: grid;
  grid-gap: 2px;
  border-radius: 12px;
  overflow: hidden;
`

const WalletWrapper = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
`

interface WalletModalProps extends InjectedModalProps {
  onDismiss?: () => void
}

export default function WalletModal({ 
  onDismiss, 
  }: WalletModalProps) {
  const { connector, chainId } = useWeb3React()

  const connections = getConnections()

  const { activationState } = useActivationState()

  // Keep the network connector in sync with any active user connector to prevent chain-switching on wallet disconnection.
  useEffect(() => {
    if (chainId && isSupportedChain(chainId) && connector !== networkConnection.connector) {
      networkConnection.connector.activate(chainId)
    }
  }, [chainId, connector])

  return (
    <ModalContainer minWidth="320px">
      <ModalHeader background="linear-gradient(139.73deg,#3a56ed 0%,#F3EFFF 100%)">
        <ModalTitle>
          <Heading>Choose Network</Heading>
        </ModalTitle>
        <ModalCloseButton onDismiss={onDismiss} />
      </ModalHeader>
      <ModalBody width={['320px', null, '340px']}>
        <WalletWrapper py="24px" maxHeight="453px" overflowY="auto">

      {activationState.status === ActivationStatus.ERROR ? (
        <ConnectionErrorView onDismiss={onDismiss} />
      ) : (
        <AutoColumn gap="16px">
          <OptionGrid data-testid="option-grid">
            {connections
              .filter((connection) => connection.shouldDisplay())
              .map((connection) => (
                <Option key={connection.getName()} connection={connection} onDismiss={onDismiss} />
              ))}
          </OptionGrid>
        </AutoColumn>
      )}
        </WalletWrapper>
      </ModalBody>
    </ModalContainer>
  )
}

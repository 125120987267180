import React from 'react'

export default function YoutubeIcon({ ...props }) {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
      <path d="M16.275 14L12.6 11.9V16.1L16.275 14Z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14ZM19.425 9.3625C20.0375 9.5375 20.475 9.975 20.65 10.5875C21 11.725 21 14 21 14C21 14 21 16.275 20.7375 17.4125C20.5625 18.025 20.125 18.4625 19.5125 18.6375C18.375 18.9 14 18.9 14 18.9C14 18.9 9.5375 18.9 8.4875 18.6375C7.875 18.4625 7.4375 18.025 7.2625 17.4125C7 16.275 7 14 7 14C7 14 7 11.725 7.175 10.5875C7.35 9.975 7.78751 9.5375 8.40001 9.3625C9.53751 9.1 13.9125 9.1 13.9125 9.1C13.9125 9.1 18.375 9.1 19.425 9.3625Z"
        fill="currentColor"
      />
    </svg>
  )
}

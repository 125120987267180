import { StaticJsonRpcProvider } from '@ethersproject/providers'
import getRpcUrl from 'utils/getRpcUrl'
import { ChainId } from 'config/constants/networks'

// const RPC_URL = getRpcUrl()
const ethProvider = new StaticJsonRpcProvider(getRpcUrl(ChainId.ETHEREUM))
const rinkebyProvider = new StaticJsonRpcProvider(getRpcUrl(ChainId.RINKEBY))
const bscProvider = new StaticJsonRpcProvider(getRpcUrl(ChainId.MAINNET))
const bsctestProvider = new StaticJsonRpcProvider(getRpcUrl(ChainId.TESTNET))
const polygonProvider = new StaticJsonRpcProvider(getRpcUrl(ChainId.POLYGON))
const mumbaiProvider = new StaticJsonRpcProvider(getRpcUrl(ChainId.MUMBAI))

// export const simpleRpcProvider = new StaticJsonRpcProvider(RPC_URL)

export const simpleRpcProvider = (chainId = 56) => {
  let provider
  switch (chainId) {
    case ChainId.ETHEREUM:
      provider = ethProvider
      break
    case ChainId.RINKEBY:
      provider = rinkebyProvider
      break
    case ChainId.MAINNET:
      provider = bscProvider
      break
    case ChainId.TESTNET:
      provider = bsctestProvider
      break
    case ChainId.POLYGON:
      provider = polygonProvider
      break
    case ChainId.MUMBAI:
      provider = mumbaiProvider
      break
    default:
      provider = bscProvider
  }

  return provider
}

export default null

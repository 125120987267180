import React from 'react'
import styled from 'styled-components'

import { DashboardIcon, BonusIcon, PayIcon, StakeIcon, IdoIcon, BridgeIcon } from '../../Icons'
import MenuItem from './MenuItem'

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`
const MENU_LINKS = [
  {
    icon: <DashboardIcon />,
    title: 'Home',
    to: '/',
    active: true,
  },
  {
    icon: <StakeIcon />,
    title: 'Stake',
    to: '/stake',
    active: false,
  },
  {
    icon: <BridgeIcon />,
    title: 'Bridge',
    to: '/bridge',
    active: false,
  },
  {
    icon: <StakeIcon />,
    title: 'BPAY Migration',
    to: '/migration',
    active: false,
  },
  {
    icon: <BonusIcon />,
    title: 'BPAY Bonus',
    to: '/bpaybonus',
    active: false,
  },
  {
    icon: <IdoIcon />,
    title: 'IDO Vesting',
    to: '/vesting',
    active: false,
  },
  // {
  //   icon: <PayIcon />,
  //   title: 'Pay',
  //   to: '/pay',
  //   active: false,
  // },
  // {
  //   icon: <StakeIcon />,
  //   title: 'Migration',
  //   to: '/migration',
  //   active: true,
  // },
]

export default function SideMenu() {
  return (
    <Wrapper>
      {MENU_LINKS.map((item, index) => (
        <MenuItem key={`k-${item.title}`} {...item} />
      ))}
    </Wrapper>
  )
}

import { createReducer } from '@reduxjs/toolkit'
import { ChainId } from '@uniswap/sdk-core'
import { shallowEqual } from 'react-redux'

import { Wallet } from './types'

import {
  addConnectedWallet,
} from './actions'

export interface WalletState {
  connectedWallets: Wallet[]
}

const initialState: WalletState = {
  connectedWallets: [],
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(addConnectedWallet, (state, { payload }) => {
      if (state.connectedWallets.some((wallet) => shallowEqual(payload, wallet))) return
      state.connectedWallets = [...state.connectedWallets, payload]
    })
)

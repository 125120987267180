import React from 'react'

export default function TwitterIcon({ ...props }) {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14ZM19.3375 10.0625C19.95 9.975 20.475 9.8875 21 9.625C20.65 10.2375 20.125 10.7625 19.5125 11.1125C19.6875 15.225 16.7125 19.6875 11.375 19.6875C9.8 19.6875 8.3125 19.1625 7 18.375C8.4875 18.55 10.0625 18.1125 11.1125 17.325C9.8 17.325 8.75 16.45 8.4 15.3125C8.8375 15.4 9.275 15.3125 9.7125 15.225C8.4 14.875 7.4375 13.65 7.4375 12.3375C7.875 12.5125 8.3125 12.6875 8.75 12.6875C7.525 11.8125 7.0875 10.15 7.875 8.8375C9.3625 10.5875 11.4625 11.725 13.825 11.8125C13.3875 10.0625 14.7875 8.3125 16.625 8.3125C17.4125 8.3125 18.2 8.6625 18.725 9.1875C19.425 9.0125 20.0375 8.8375 20.5625 8.4875C20.3875 9.1875 19.95 9.7125 19.3375 10.0625Z"
        fill="currentColor"
      />
    </svg>
  )
}

// @ts-nocheck
import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'

import useToast from 'hooks/useToast'
import { useAppDispatch } from 'state'
import { useTranslation } from 'contexts/Localization'
import { useUpdateSelectedWallet } from 'state/user/hooks'

const useAuth = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { chainId, connector } = useWeb3React()
  const { toastError } = useToast()

  const updateSelectedWallet = useUpdateSelectedWallet();

  const logout = useCallback(() => {
    //deactivate()
    //clearUserStates(dispatch, chainId)

    if (connector && connector.deactivate) {
      connector.deactivate()
    }
    connector.resetState()
    updateSelectedWallet({ wallet: undefined })

  }, [connector])

  return { logout }
}

export default useAuth

import React from 'react'

export default function StakeIcon({ ...props }) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <rect width="32" height="32" rx="6" fill="currentColor" />
      <path
        d="M17 6L7 18H16L15 26L25 14H16L17 6Z"
        fill="#10131B"
        stroke="#10131B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

import React from 'react'
import { Button, Flex, useModal } from 'paybolt-uikit'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'

import WalletModal from 'components/WalletModal'

const ConnectWalletButton = (props) => {
  const { t } = useTranslation()

  const ButtonRow = styled.div`
    position: relative;
  `

  const [onShowConnectModal] = useModal(<WalletModal />)

  return (
    <ButtonRow>
      <Button
        onClick={onShowConnectModal}
        {...props}
        style={{ backgroundColor: '#2562FF', minWidth: '190px', minHeight: '52px' }}
      >
        {t('Connect')}
      </Button>
    </ButtonRow>
  )
}

export default ConnectWalletButton

import poolTokens from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

export const tierType = {
  0: 'Shark',
  1: 'Octopus',
  2: 'Crab',
  3: 'Shrimp',
}

export const tierColor = {
  0: '#29D1E8',
  1: '#FEB03B',
  2: '#4274C5',
  3: '#853BFE',
}

export const tierApproxValue = {
  0: '~20,000 USD',
  1: '~10,000 USD',
  2: '~5,000 USD',
  3: '~500 USD',
}

export const tierBadgePath = {
  0: { path: '/images/stake/glow-whale.svg', alt: 'glow-whale' },
  1: { path: '/images/stake/glow-shark.svg', alt: 'glow-shark' },
  2: { path: '/images/stake/glow-dolphin.svg', alt: 'glow-dolphin' },
  3: { path: '/images/stake/glow-seal.svg', alt: 'glow-seal' },
}

const pools: SerializedPoolConfig[] = [
  {
    sousId: 100,
    stakingToken: poolTokens.pay,
    earningToken: poolTokens.pay,
    contractAddress: {
      1: '0xC0ff10175f2e3a3917aAe3DEB985C60Fa577a055',
      4: '0x67601C71bc0cA35323Fe7DF3b2963FFd4d24C8B1',
      56: '0x84a7F16c604B4F421b8584E2AC0b9c2A008a89EA',
      97: '0x7d4B08b8a5342374D01832E4d3FbfD1e3e92A760',
      137: '0xC0ff10175f2e3a3917aAe3DEB985C60Fa577a055',
      80001: '0x4B32CB8ccb9A87D92C48e8b23C84C810AbbB80E4',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.02679',
    tierIndex: 0,
  },
  {
    sousId: 101,
    stakingToken: poolTokens.pay,
    earningToken: poolTokens.pay,
    contractAddress: {
      1: '0xC0ff10175f2e3a3917aAe3DEB985C60Fa577a055',
      4: '0x67601C71bc0cA35323Fe7DF3b2963FFd4d24C8B1',
      56: '0x84a7F16c604B4F421b8584E2AC0b9c2A008a89EA',
      97: '0x7d4B08b8a5342374D01832E4d3FbfD1e3e92A760',
      137: '0xC0ff10175f2e3a3917aAe3DEB985C60Fa577a055',
      80001: '0x4B32CB8ccb9A87D92C48e8b23C84C810AbbB80E4',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.02679',
    tierIndex: 1,
  },
  {
    sousId: 102,
    stakingToken: poolTokens.pay,
    earningToken: poolTokens.pay,
    contractAddress: {
      1: '0xC0ff10175f2e3a3917aAe3DEB985C60Fa577a055',
      4: '0x67601C71bc0cA35323Fe7DF3b2963FFd4d24C8B1',
      56: '0x84a7F16c604B4F421b8584E2AC0b9c2A008a89EA',
      97: '0x7d4B08b8a5342374D01832E4d3FbfD1e3e92A760',
      137: '0xC0ff10175f2e3a3917aAe3DEB985C60Fa577a055',
      80001: '0x4B32CB8ccb9A87D92C48e8b23C84C810AbbB80E4',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.02679',
    tierIndex: 2,
  },
  {
    sousId: 103,
    stakingToken: poolTokens.pay,
    earningToken: poolTokens.pay,
    contractAddress: {
      1: '0xC0ff10175f2e3a3917aAe3DEB985C60Fa577a055',
      4: '0x67601C71bc0cA35323Fe7DF3b2963FFd4d24C8B1',
      56: '0x84a7F16c604B4F421b8584E2AC0b9c2A008a89EA',
      97: '0x7d4B08b8a5342374D01832E4d3FbfD1e3e92A760',
      137: '0xC0ff10175f2e3a3917aAe3DEB985C60Fa577a055',
      80001: '0x4B32CB8ccb9A87D92C48e8b23C84C810AbbB80E4',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.02679',
    tierIndex: 3,
  },
]

export default pools

import { UAParser } from 'ua-parser-js'

const parser = typeof window !== 'undefined' ? new UAParser(window.navigator.userAgent) : false
const { type } = parser ? parser.getDevice() : ''
const { name } = parser ? parser.getBrowser() : ''

export const isMobile = type === 'mobile' || type === 'tablet'
const platform = parser ? parser.getOS().name : ''
export const isIOS = platform === 'iOS'
export const isNonIOSPhone = !isIOS && type === 'mobile'

export const isMobileSafari = isMobile && isIOS && name?.toLowerCase().includes('safari')

import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from 'paybolt-uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Kanit', sans-serif;
  }
  html {
    height: 100%; 
  }
  body {    
    background-color: #10131b;
    height: 100%;
    overflow-x: hidden;

    img {
      height: auto;
      max-width: 100%;
    }

    // &:after {
    //   overflow-y: hidden;
    //   content: "";
    //   position: absolute;
    //   bottom: 0;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   width: 2559px;
    //   height: 300px;
    //   background: url(/images/big-line.png) center 0 no-repeat;
    //   background-size: stretch;
    //   z-index: -1;
    //   opacity: 0.4;
    // }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1265px;
      left: 50%;
      transform: translateX(-50%);
      top: -661px;
      background: radial-gradient( 50% 50% at 50% 50%, #3a445f 0%, rgba(16, 19, 27, 0) 100% );
      filter: blur(34px);
      z-index: -1;
    }
  }
  #__next {
    height: 100%;
  }
`

export default GlobalStyle

// @ts-nocheck
import React from 'react'
import styled from 'styled-components'
import { Button, Text } from 'paybolt-uikit'

interface Props {
  title: string
  icon: Icon
  chainId: number
  selected: boolean
  onClick: (networkId: number) => void
  onDismiss: () => void
}

const NetworkButton = styled(Button).attrs({ width: '100%', variant: 'text', py: '16px' })`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
`

const NetworkCard: React.FC<Props> = ({ title, icon: Icon, chainId, selected, onClick, onDismiss }) => {
  return (
    <NetworkButton
      variant="tertiary"
      onClick={() => {
        onClick(chainId)
        onDismiss()
      }}
      id={`network-connect-${title.toLocaleLowerCase()}`}
      style={{ backgroundColor: selected ? '#ccc' : 'none' }}
    >
      <Icon width="40px" mb="8px" />
      <Text fontSize="14px">{title}</Text>
    </NetworkButton>
  )
}

export default NetworkCard

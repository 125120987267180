import poolsConfig from 'config/constants/pools'
import tierChefABI from 'config/abi/tierMasterchef.json'
import erc20ABI from 'config/abi/erc20.json'
import multicall from 'utils/multicall'
import { getAddress } from 'utils/addressHelpers'
import { simpleRpcProvider } from 'utils/providers'
import BigNumber from 'bignumber.js'

export const fetchPoolsAllowance = async (account, chainId) => {
  const nonBnbPools = poolsConfig.filter(
    (pool) => pool.stakingToken.symbol !== 'BNB' && pool.contractAddress[chainId] !== '',
  )
  const calls = nonBnbPools.map((pool) => ({
    address: pool.stakingToken.address[chainId],
    name: 'allowance',
    params: [account, getAddress(pool.contractAddress, chainId)],
  }))

  const allowances = await multicall(erc20ABI, calls, chainId)
  return nonBnbPools.reduce(
    (acc, pool, index) => ({ ...acc, [pool.sousId]: new BigNumber(allowances[index]).toJSON() }),
    {},
  )
}

export const fetchUserBalances = async (account, chainId) => {
  const nonBnbPools = poolsConfig.filter(
    (pool) => pool.stakingToken.symbol !== 'BNB' && pool.contractAddress[chainId] !== '',
  )
  const bnbPools = poolsConfig.filter(
    (pool) => pool.stakingToken.symbol === 'BNB' && pool.contractAddress[chainId] !== '',
  )
  // Non BNB pools
  try {
    const calls = nonBnbPools.map((pool) => ({
      address: pool.stakingToken.address[chainId],
      name: 'balanceOf',
      params: [account],
    }))
    const tokenBalancesRaw = await multicall(erc20ABI, calls, chainId)
    const tokenBalances = nonBnbPools.reduce(
      (acc, pool, index) => ({ ...acc, [pool.sousId]: new BigNumber(tokenBalancesRaw[index]).toJSON() }),
      {},
    )

    // BNB pools
    const bnbBalance = await simpleRpcProvider(chainId).getBalance(account)
    const bnbBalances = bnbPools.reduce(
      (acc, pool) => ({ ...acc, [pool.sousId]: new BigNumber(bnbBalance.toString()).toJSON() }),
      {},
    )

    return { ...tokenBalances, ...bnbBalances }
  } catch {
    return null
  }
}

export const fetchUserStakeBalances = async (account, chainId) => {
  const nonBnbPools = poolsConfig.filter(
    (pool) => pool.stakingToken.symbol !== 'BNB' && pool.contractAddress[chainId] !== '',
  )
  const calls = nonBnbPools.map((p) => ({
    address: getAddress(p.contractAddress, chainId),
    name: 'userPoolInfo',
    params: [p.tierIndex, account],
  }))
  const userInfo = await multicall(tierChefABI, calls, chainId)

  const stakedBalances = nonBnbPools.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.sousId]: [
        new BigNumber(userInfo[index]?.amount._hex).toJSON(),
        new BigNumber(userInfo[index]?.timeDeposited._hex).toJSON(),
      ],
    }),
    {},
  )

  return stakedBalances
}

export const fetchUserPendingRewards = async (account, chainId) => {
  const nonBnbPools = poolsConfig.filter(
    (pool) => pool.stakingToken.symbol !== 'BNB' && pool.contractAddress[chainId] !== '',
  )
  const calls = nonBnbPools.map((p) => ({
    address: getAddress(p.contractAddress, chainId),
    name: 'pendingReward',
    params: [p.tierIndex, account],
  }))
  const res = await multicall(tierChefABI, calls, chainId)
  const pendingRewards = nonBnbPools.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.sousId]: new BigNumber(res[index]).toJSON(),
    }),
    {},
  )

  return pendingRewards
}

import React from 'react'

export default function IdoIcon({ ...props }) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <rect width="32" height="32" rx="8" fill="currentColor" />
      <path d="M16 24V14" stroke="black" strokeWidth="2.3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22 24V8" stroke="black" strokeWidth="2.3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 24V20" stroke="black" strokeWidth="2.3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

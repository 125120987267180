import React from 'react'

export default function DashboardIcon({ ...props }) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M0 9.6C0 6.23969 0 4.55953 0.653961 3.27606C1.2292 2.14708 2.14708 1.2292 3.27606 0.653961C4.55953 0 6.23969 0 9.6 0H22.4C25.7603 0 27.4405 0 28.7239 0.653961C29.8529 1.2292 30.7708 2.14708 31.346 3.27606C32 4.55953 32 6.23969 32 9.6V22.4C32 25.7603 32 27.4405 31.346 28.7239C30.7708 29.8529 29.8529 30.7708 28.7239 31.346C27.4405 32 25.7603 32 22.4 32H9.6C6.23969 32 4.55953 32 3.27606 31.346C2.14708 30.7708 1.2292 29.8529 0.653961 28.7239C0 27.4405 0 25.7603 0 22.4V9.6Z"
        fill="currentColor"
      />
      <path
        d="M12 6.99916H9C7.89543 6.99916 7 7.89459 7 8.99916V11.9992C7 13.1037 7.89543 13.9992 9 13.9992H12C13.1046 13.9992 14 13.1037 14 11.9992V8.99916C14 7.89459 13.1046 6.99916 12 6.99916Z"
        fill="#10131B"
        stroke="#10131B"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 6.99916H20C18.8954 6.99916 18 7.89459 18 8.99916V11.9992C18 13.1037 18.8954 13.9992 20 13.9992H23C24.1046 13.9992 25 13.1037 25 11.9992V8.99916C25 7.89459 24.1046 6.99916 23 6.99916Z"
        stroke="#10131B"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 17.9992H20C18.8954 17.9992 18 18.8946 18 19.9992V22.9992C18 24.1037 18.8954 24.9992 20 24.9992H23C24.1046 24.9992 25 24.1037 25 22.9992V19.9992C25 18.8946 24.1046 17.9992 23 17.9992Z"
        stroke="#10131B"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 17.9992H9C7.89543 17.9992 7 18.8946 7 19.9992V22.9992C7 24.1037 7.89543 24.9992 9 24.9992H12C13.1046 24.9992 14 24.1037 14 22.9992V19.9992C14 18.8946 13.1046 17.9992 12 17.9992Z"
        stroke="#10131B"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import poolsConfig from 'config/constants/pools'
import { PoolsState, SerializedPool, AppThunk, IfoVaultUser } from 'state/types'
import { BIG_ZERO } from 'utils/bigNumber'
import { useGetPayBoltPrice } from 'hooks/useTokenBalance'
import { fetchPoolsTotalStaking } from './fetchPools'
import {
  fetchPoolsAllowance,
  fetchUserBalances,
  fetchUserStakeBalances,
  fetchUserPendingRewards,
} from './fetchPoolsUser'
import fetchIfoPoolUserData from './fetchIfoPoolUser'

export const initialPoolVaultState = Object.freeze({
  totalShares: null,
  pricePerFullShare: null,
  totalCakeInVault: null,
  estimatedCakeBountyReward: null,
  totalPendingCakeHarvest: null,
  fees: {
    performanceFee: null,
    callFee: null,
    withdrawalFee: null,
    withdrawalFeePeriod: null,
  },
  userData: {
    isLoading: true,
    userShares: null,
    cakeAtLastUserAction: null,
    lastDepositedTime: null,
    lastUserActionTime: null,
    credit: null,
  },
  creditStartBlock: null,
})

const initialState: PoolsState = {
  data: [...poolsConfig],
  userDataLoaded: false,
  cakeVault: initialPoolVaultState,
  ifoPool: initialPoolVaultState,
}

// Thunks
export const fetchPoolsPublicDataAsync = (chainId: number, account: string) => async (dispatch, getState) => {
  const poolArray = poolsConfig.filter((pool) => pool.contractAddress[chainId] !== '')
  const totalStakings = await fetchPoolsTotalStaking(chainId, account)
  const price = useGetPayBoltPrice()

  const liveData = poolArray.map((pool) => {
    const totalStaking = totalStakings.find((entry) => entry.sousId === pool.sousId)

    const stakingTokenAddress = pool.stakingToken.address ? pool.stakingToken.address[chainId].toLowerCase() : null
    const stakingTokenPrice = stakingTokenAddress ? price : 0

    const earningTokenAddress = pool.earningToken.address ? pool.earningToken.address[chainId].toLowerCase() : null
    const earningTokenPrice = earningTokenAddress ? price : 0

    return {
      ...totalStaking,
      stakingTokenPrice,
      earningTokenPrice,
    }
  })

  dispatch(setPoolsPublicData(liveData))
}

export const fetchPoolsUserDataAsync =
  (account: string, chainId: number): AppThunk =>
  async (dispatch) => {
    const poolArray = poolsConfig.filter((pool) => pool.contractAddress[chainId] !== '')
    const allowances = await fetchPoolsAllowance(account, chainId)
    const stakingTokenBalances = await fetchUserBalances(account, chainId)
    const stakedBalances = await fetchUserStakeBalances(account, chainId)
    const pendingRewards = await fetchUserPendingRewards(account, chainId)

    const userData = poolArray.map((pool) => ({
      sousId: pool.sousId,
      allowance: allowances[pool.sousId],
      stakingTokenBalance: stakingTokenBalances ? stakingTokenBalances[pool.sousId] : BIG_ZERO,
      stakedBalance: stakedBalances[pool.sousId][0],
      timeDeposited: stakedBalances[pool.sousId][1],
      pendingReward: pendingRewards[pool.sousId],
    }))

    dispatch(setPoolsUserData(userData))
  }

export const updateUserAllowance =
  (sousId: number, account: string, chainId: number): AppThunk =>
  async (dispatch) => {
    const allowances = await fetchPoolsAllowance(account, chainId)
    dispatch(updatePoolsUserData({ sousId, field: 'allowance', value: allowances[sousId] }))
  }

export const updateUserBalance =
  (sousId: number, account: string, chainId: number): AppThunk =>
  async (dispatch) => {
    const tokenBalances = await fetchUserBalances(account, chainId)
    dispatch(updatePoolsUserData({ sousId, field: 'stakingTokenBalance', value: tokenBalances[sousId] }))
  }

export const updateUserStakedBalance =
  (sousId: number, account: string, chainId: number): AppThunk =>
  async (dispatch) => {
    const stakedBalances = await fetchUserStakeBalances(account, chainId)
    dispatch(updatePoolsUserData({ sousId, field: 'stakedBalance', value: stakedBalances[sousId][0] }))
    dispatch(updatePoolsUserData({ sousId, field: 'timeDeposited', value: stakedBalances[sousId][1] }))
  }

export const updateUserPendingReward =
  (sousId: number, account: string, chainId: number): AppThunk =>
  async (dispatch) => {
    const pendingRewards = await fetchUserPendingRewards(account, chainId)
    dispatch(updatePoolsUserData({ sousId, field: 'pendingReward', value: pendingRewards[sousId] }))
  }

export const fetchIfoPoolUserAndCredit = createAsyncThunk<IfoVaultUser, { account: string; chainId: number }>(
  'ifoPool/fetchUser',
  async ({ account, chainId }) => {
    const userData = await fetchIfoPoolUserData(account, chainId)
    return userData
  },
)

export const PoolsSlice = createSlice({
  name: 'Pools',
  initialState,
  reducers: {
    setPoolsPublicData: (state, action) => {
      const livePoolsData: SerializedPool[] = action.payload
      state.data = state.data.map((pool) => {
        const livePoolData = livePoolsData.find((entry) => entry.sousId === pool.sousId)
        return { ...pool, ...livePoolData }
      })
    },
    setPoolsUserData: (state, action) => {
      const userData = action.payload
      state.data = state.data.map((pool, index) => {
        const userPoolData = userData.find((entry) => entry.sousId === pool.sousId)
        return { ...pool, userData: userPoolData }
      })
      state.userDataLoaded = true
    },
    updatePoolsUserData: (state, action) => {
      const { field, value, sousId } = action.payload
      const index = state.data.findIndex((p) => p.sousId === sousId)

      if (index >= 0) {
        state.data[index] = { ...state.data[index], userData: { ...state.data[index].userData, [field]: value } }
      }
    },
  },
})

// Actions
export const { setPoolsPublicData, setPoolsUserData, updatePoolsUserData } = PoolsSlice.actions

export default PoolsSlice.reducer
